import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import mailReducer from './slices/mail';
import chatReducer from './slices/chat';
import productReducer from './slices/product';
import calendarReducer from './slices/calendar';
import kanbanReducer from './slices/kanban';
import depotReducer from './slices/depot';
import clientsReducer from './slices/clients';
import vehicleReducer from './slices/vehicle';
import geocodeReducer from './slices/geocode';
import garageReducer from './slices/garage';

import nomenclatureReducer from './slices/nomenclature';
import offerReducer from './slices/offer';
import requestReducer from './slices/request';
import auctionReducer from './slices/auction';
import regionReducer from './slices/region';
import statusReducer from './slices/status';
import resellerReducer from './slices/reseller';

// ----------------------------------------------------------------------

const rootPersistConfig = {
    key: 'root',
    storage,
    keyPrefix: 'redux-',
    whitelist: [],
};

const productPersistConfig = {
    key: 'product',
    storage,
    keyPrefix: 'redux-',
    whitelist: ['sortBy', 'checkout'],
};

const appReducer = combineReducers({
    mail: mailReducer,
    chat: chatReducer,
    calendar: calendarReducer,
    kanban: kanbanReducer,
    product: persistReducer(productPersistConfig, productReducer),
    depot: depotReducer,
    clients: clientsReducer,
    vehicles: vehicleReducer,
    garage: garageReducer,
    geocodes: geocodeReducer,

    nomenclature: nomenclatureReducer,
    auction: auctionReducer,
    request: requestReducer,
    offer: offerReducer,
    region: regionReducer,
    status: statusReducer,
    reseller: resellerReducer
});

const rootReducer = (state: any, action: any) => {
    if (action.type === 'RESET_STORE') {
        return appReducer(undefined, action)
    }

    return appReducer(state, action)
}

export { rootPersistConfig, rootReducer };
