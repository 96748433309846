import { dispatch } from '../store';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axiosDistributor from '../../utils/axiosDistributor';
// @types
import {Reseller, ResellerState, ResellerStatus} from "../../@types/reseller";

// ----------------------------------------------------------------------

const initialState: ResellerState = {
    isLoading: false,
    error: null,
    loaded: false,
    resellers: []
};

const slice = createSlice({
    name: 'reseller',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET RESELLERS
        getResellersSuccess(state, action) {
            state.isLoading = false;
            state.error = null;
            state.loaded = true;
            state.resellers = action.payload;
        },
        
        // ADD RESELLER
        addReseller(state, action) {
            state.resellers = [...state.resellers].concat(action.payload);
        },

        // DELETE RESELLER
        deleteReseller(state, action) {
            state.resellers = [...state.resellers.filter(x => x.id !== action.payload)];
        },
    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// Получение списка вторичников
export function getResellers() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axiosDistributor.get('/resellers');
            dispatch(slice.actions.getResellersSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export async function addReseller(inn: string, kpp: string) {
    let _request = {
        Inn: inn,
        Kpp: kpp
    }

    const response = await axiosDistributor.post(
        '/resellers',
        _request
    );
    dispatch(slice.actions.addReseller(response.data));
}

export function deleteReseller(id: string) {
    return async () => {
        try {
            const response = await axiosDistributor.delete(`/resellers/{${id}}`);
            dispatch(slice.actions.deleteReseller(id));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}