import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const theme = useTheme();
  //const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  //const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg width="100%" height="100%" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.0013 5.00002C17.3729 4.99621 14.7884 5.64222 12.5 6.875H27.5C25.2123 5.6428 22.6288 4.99681 20.0013 5.00002Z" fill="#003087"/>
        <path d="M18.8314 10.625C19.2255 10.2243 19.6476 9.84624 20.0952 9.49324H26.0763L26.8236 8.82537H31.25C30.8109 8.4643 30.3489 8.12612 29.8662 7.8125H11.9688C11.8383 7.90108 11.6975 7.992 11.5645 8.08524L11.481 8.14352C11.3506 8.2356 11.2201 8.32884 11.0897 8.42559L11.0441 8.45939C10.9228 8.55147 10.8041 8.64471 10.6867 8.73912L10.6333 8.78225C10.5028 8.88482 10.3842 8.98855 10.2629 9.09462L10.2094 9.14241C10.0881 9.24847 9.96942 9.35687 9.85205 9.46643L9.84422 9.47343L9.82335 9.49324C9.44259 9.85259 9.08426 10.2304 8.75 10.625H18.8314Z" fill="#003087"/>
        <path d="M25.8888 25.7009H33.6853C33.8261 25.3693 33.9531 25.0307 34.0625 24.6875H5.9375C5.97397 24.7972 6.01169 24.907 6.05193 25.0156C6.06325 25.0494 6.07582 25.0821 6.0884 25.1148C6.11732 25.1942 6.1475 25.2736 6.17768 25.3483L6.21792 25.4499C6.2569 25.5503 6.2984 25.6495 6.34367 25.7476C6.36127 25.7896 6.38014 25.8316 6.399 25.8737C6.41786 25.9157 6.45181 25.9904 6.47948 26.0523C6.50714 26.1142 6.52726 26.1562 6.55241 26.2076L6.62157 26.3512C6.67313 26.4551 6.72595 26.5578 6.77876 26.6606C6.79511 26.6898 6.80894 26.7201 6.82529 26.7493C6.9611 27.0038 7.10445 27.2548 7.25786 27.5H16.8261C16.6538 27.256 16.4929 27.005 16.3407 26.7493H24.7659L25.8888 25.7009Z" fill="#003087"/>
        <path d="M25.9731 29.45H31.3493C31.6456 29.1242 31.925 28.7867 32.1875 28.4375H7.8125C7.90113 28.5542 7.99356 28.6708 8.08599 28.7875L8.16703 28.8866C8.2392 28.9741 8.31137 29.0616 8.38607 29.1479L8.46331 29.2377C8.56207 29.3544 8.66336 29.464 8.76592 29.5737L8.77732 29.5865C8.87735 29.6927 8.97864 29.7965 9.0812 29.8992C9.10779 29.9272 9.13564 29.954 9.16223 29.9808C9.2496 30.0671 9.33697 30.1511 9.42686 30.2351L9.49777 30.3028C9.60413 30.4008 9.71175 30.4988 9.82191 30.5944C10.0819 30.8223 10.3503 31.0408 10.6272 31.25H20.9275C20.578 31.0462 20.2404 30.8277 19.9145 30.5944H24.726L25.9731 29.45Z" fill="#003087"/>
        <path d="M14.6275 19.0625C14.6469 18.7453 14.6819 18.4317 14.7272 18.1215H25.1226L26.0756 17.2621H35C34.9288 16.9205 34.8446 16.5823 34.741 16.25H5.48688C5.44544 16.3876 5.40789 16.5252 5.37293 16.6639C5.36257 16.7024 5.35221 16.7421 5.34315 16.7805C5.31595 16.889 5.29006 16.9974 5.26675 17.1059L5.24344 17.212C5.18258 17.5 5.13078 17.795 5.09064 18.0877C5.09064 18.0993 5.09064 18.1098 5.09064 18.1215C5.04921 18.4328 5.01813 18.7465 5 19.0625H14.6275Z" fill="#003087"/>
        <path d="M19.5332 35C22.3561 35.005 25.121 34.0289 27.5 32.1875H11.5625C13.9427 34.0293 16.709 35.0054 19.5332 35Z" fill="#003087"/>
        <path d="M15.4507 15.3125C15.5979 14.8461 15.764 14.3797 15.9441 13.9319H24.9119L25.7427 12.9136H33.125C32.9297 12.4502 32.7183 11.9999 32.4908 11.5625H7.41015C7.32262 11.7335 7.23764 11.9061 7.15646 12.0802L7.08289 12.2357C7.02327 12.3647 6.96366 12.4938 6.90658 12.6228C6.88121 12.6788 6.85711 12.7348 6.83301 12.7907C6.76959 12.9384 6.70617 13.0861 6.64782 13.2354L6.60596 13.338C6.53239 13.523 6.46263 13.7096 6.39413 13.8977L6.38272 13.9288C6.22036 14.3797 6.07069 14.8399 5.9375 15.3094L15.4507 15.3125Z" fill="#003087"/>
        <path d="M5.01668 20.6032L5.02437 20.7587C5.03207 20.9142 5.04105 21.0805 5.0526 21.2407L5.06029 21.3604C5.07569 21.5609 5.09365 21.7615 5.11418 21.9605C5.11418 21.9823 5.11418 22.0025 5.11418 22.0227C5.13342 22.2046 5.15523 22.3865 5.17832 22.5669L5.18602 22.6228C5.23733 23.0022 5.29634 23.3784 5.36562 23.75H14.9448C14.8679 23.38 14.802 23.0042 14.7473 22.6228H24.9038L25.9545 21.3495H34.941C34.9743 20.9049 34.9936 20.454 35 20H5C5.00257 20.2068 5.00898 20.4058 5.01668 20.6032Z" fill="#003087"/>
      </svg>

    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink key="logo" to="/">{logo}</RouterLink>;
}
