import { dispatch } from '../store';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axiosDistributor from '../../utils/axiosDistributor';
// @types
import { RegionState, ReportData } from 'src/@types/region';
import { fDateFrom, fDateTo } from "../../utils/formatTime";

// ----------------------------------------------------------------------

const initialState: RegionState = {
    isLoading: false,
    error: null,
    loaded: false,
    regions: []
};

const slice = createSlice({
    name: 'region',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET REGIONS
        getRegionsSuccess(state, action) {
            state.isLoading = false;
            state.error = null;
            state.loaded = true;
            state.regions = action.payload;
        }
    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// Получение списка регионов
export function getRegions() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axiosDistributor.get('/region');
            dispatch(slice.actions.getRegionsSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export async function getReport(reportData: ReportData) {
    let _request = {
        regionIds: reportData.regionIds,
        dateFrom: fDateFrom(reportData.dateFrom ?? new Date()),
        dateTo: fDateTo(reportData.dateTo ?? new Date())
    }
    const response = await axiosDistributor.post(`/report`, _request);
    return response.data;
}
