import _mock from './_mock';
import { PagedApiResponse } from '../@types/common';
import { Depot, DepotType } from 'src/@types/depot';

// ----------------------------------------------------------------------

const total = 150;

function generateDepot(index: number): Depot {
    return {
        depotId: index,
        description: 'Склад' + Math.floor(Math.random() * 20 + 1),
        address: 'Улица' + Math.floor(Math.random() * 20 + 1),
        timeWindow: _mock.time(index),
        type: DepotType.depot,
        pointLat: Math.random() * 100,
        pointLon: Math.random() * 100,
    };
}

const depots: Depot[] = new Array(total).fill(1).map((value, index) => generateDepot(index));

export const _depotsPagedApiResponse: { data: PagedApiResponse<Depot[]> } = {
    data: {
        total: total,
        currentPage: 0,
        totalPages: 2,
        data: depots,
    },
};
