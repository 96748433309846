// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { TextField, TextFieldProps } from '@mui/material';
import { LocalizationProvider, DatePicker, DatePickerProps } from '@mui/lab';
import ruLocale from 'date-fns/locale/ru';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

// ----------------------------------------------------------------------

interface IProps {
  name: string;
  readOnly: boolean;
}

export default function RHFDatePicker({ name, readOnly, ...other }: IProps & TextFieldProps) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
          <DatePicker
            {...field}
            readOnly={readOnly}
            inputFormat="dd.MM.yyyy"
            mask="__.__.____"
            onChange={(date) => {
              field.onChange(date);
            }}
            renderInput={(params) => <TextField size="small" {...params} {...other} />}
          />
        </LocalizationProvider>
      )}
    />
  );
}
