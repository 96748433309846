import { dispatch } from '../store';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axiosDelivery from '../../utils/axiosDelivery';
// @types
import { Garage, GarageDTO, GarageState } from 'src/@types/garage';
import { PagedApiResponse } from 'src/@types/common';

// ----------------------------------------------------------------------

const initialState: GarageState = {
    isLoading: false,
    error: null,
    garage: {
        total: 0,
        currentPage: 0,
        totalPages: 0,
        data: [],
    },
    selectedGarageId: [],
};

const slice = createSlice({
    name: 'garage',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET GARAGE ON DATE OR ID
        getGarageListSuccess(state, action) {
            state.isLoading = false;
            state.garage = action.payload;
        },

        // SET SELECTED GARAGE
        setSelectedGarage(state, action) {
            //state.isLoading = false;
            state.selectedGarageId = action.payload;
            state.error = null;
        },
    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// Получить гараж по дате или ID
export function getGarageList(pageSize: number, page: number, date = '', garageId = 0) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response: PagedApiResponse<Garage[]> = await axiosDelivery.get('/garage', {
                params: {
                    date: date,
                    id: garageId,
                    size: pageSize,
                    page: page,
                },
            });
            console.log('response', response);
            dispatch(slice.actions.getGarageListSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// Сохранить или обновить запись в гараже
export function sendNewGarageItem(garageItem: GarageDTO) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axiosDelivery.post('/garage', {
                params: garageItem,
            });
            console.log('response sendNewGarageItem', response);
            //dispatch(slice.actions.getVehicleDriversSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// Удалить из гаража
export function sendDeleteGarageItem(garageId: number) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axiosDelivery.delete('/garage', {
                params: { garageId: garageId },
            });
            console.log('response sendDeleteGarageItem', response);
            //dispatch(slice.actions.getVehicleDriversSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// Скопировать гараж
export function sendCopyGarage(dateFrom: string, dateTo: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const request = {
                from: dateFrom,
                to: dateTo,
            };
            const response = await axiosDelivery.post('/garage/copy', {
                params: { request },
            });
            console.log('response sendCopyGarage', response);
            //dispatch(slice.actions.getVehicleDriversSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
