import { dispatch } from '../store';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axiosDistributor from '../../utils/axiosDistributor';
// @types
import { Auction, AuctionState, DetailAuction, AuctionRequest, AuctionFilter } from 'src/@types/auction';
import { PagedApiResponse } from 'src/@types/common';
import { fDateFrom, fDateTo } from "../../utils/formatTime";
// ----------------------------------------------------------------------

const initialState: AuctionState = {
    isLoading: false,
    error: null,
    loaded: false,
    auctions: {
        total: 0,
        currentPage: 0,
        totalPages: 0,
        data: [],
        excess: false
    },
    auctionItem: null,
    auction: null,
    filter: {
        MNN: null,
        DateFrom: new Date(Date.now()),
        DateTo: new Date(Date.now()),
        tenderNumber: null,
        lotNumber: null,
        statusID: null,
        regions: null
    }
};

const slice = createSlice({
    name: 'auction',
    initialState,
    reducers: {
        startLoading(state) {
            state.isLoading = true;
        },

        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        getAuctionsSuccess(state, action) {
            state.isLoading = false;
            state.loaded = true;
            state.error = null;
            state.auctions = action.payload;
        },

        setAuctionItem(state, action) {
            state.auctionItem = action.payload;
        },

        getAuctionSuccess(state, action) {
            state.isLoading = false;
            state.error = null;
            state.auction = action.payload;
        },

        setFilter(state, action) {
            state.filter = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// Получение тендеров
export function getAuctionsList(request: AuctionRequest) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            let _request = {
                Regions: request.Regions,
                MNN: request.MNN,
                DateFrom: fDateFrom(request.DateFrom ?? new Date()),
                DateTo: fDateTo(request.DateTo ?? new Date()),
                TenderNumber: request.TenderNumber,
                LotNumber: request.LotNumber,
                StatusID: request.StatusID
            }

            const response = await axiosDistributor.post(
                '/auctions/GetList',
                _request
            );
            (response.data.list as Auction[]).sort((a, b) => new Date(a.date).getDate() - new Date(b.date).getDate() || a.tenderNumber.localeCompare(b.tenderNumber));
            let data = {
                total: response.data.list,
                currentPage: 0,
                totalPages: 0,
                data: response.data.list,
                excess: response.data.excess
            }
            dispatch(slice.actions.getAuctionsSuccess(data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

// Получение тендера по ID
export function getAuction(lotId: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response: PagedApiResponse<DetailAuction> = await axiosDistributor.get(`/auctions/${lotId}`);
            dispatch(slice.actions.getAuctionSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// Установка активного тендера
export function setAuctionItem(item: Auction | null) {
    return async () => {
        try {
            dispatch(slice.actions.setAuctionItem(item));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setAuctionFilter(filter: AuctionFilter) {
    return async () => {
        try {
            dispatch(slice.actions.setFilter(filter));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}