import { PagedApiResponseExtended } from './common';
import { NomenclatureItem } from './nomenclature';

export type Offer = {
    id: string;
    number: string;
    date: Date;
    lotNumber: string;
    tenderNumber: string;
    tenderConductingDate: Date;
    tenderEndDate: Date;
    status: string;
    distrNumber: string;
    id_Status: number;
    buyer: string;
    validity: Date | null;
};

export type OfferState = {
    isLoading: boolean;
    error: Error | string | null;
    loaded: boolean,
    offers: PagedApiResponseExtended<Offer[]>;
    offer: DetailOffer | null;
    filter: OfferFilter;
};

export type OfferRequest = {
    Regions: string[] | null;
    MNN: string | null;
    Personal: boolean | null;
    DateFrom: Date | null;
    DateTo: Date | null;
    ID_Lot: number | null;
    TenderNumber: string | null;
    LotNumber: string | null;
    ID_Status: string | null;
    Number: string | null;
    DistrNumber: string | null;
};

export type OfferFilter = {
    MNN: NomenclatureItem | null;
    ID_Lot: string | null;
    Personal: boolean | null;
    DateFrom: Date | null;
    DateTo: Date | null;
    tenderNumber: string | null;
    lotNumber: string | null;
    ID_Status: string | null;
    number: string | null;
    regions: string[] | null;
    distrNumber: string | null;
    tenderConductingDate: Date | null;
    tenderEndDate: Date | null;
};

export type DetailOffer = {
    number: string | null;
    date: Date | null;
    status: string | null;
    type: string | null;
    distrNumber: string | null;
    buyer: string | null;
    tenderNumber: string | null;
    lotNumber: string | null;
    iD_Lot: string | null;
    baseKPNumber: string | null;
    baseKP_ID: string | null;
    region: string | null;
    author: string | null;
    comment: string | null;
    validity: Date | null;
    buttonClose: boolean | null;
    buttonSave: boolean | null;
    buttonRepport: boolean | null;
    buttonCreateKP: boolean | null;
    buttonShipment: boolean | null;
    buttonPrint: boolean | null;
    buttonAddRow: boolean | null;
    buttonDelRow: boolean | null;

    listSKU: OfferCardItemSKU[];
    listSecondary: ItemSecondary[];
};

export type OfferCardItemSKU = {
    _id: string | null;
    sku: string | null;
    typePack: string | null;
    planQuantity: number | null;
    planDiscount: number | null;
    factQuantity: number | null;
    factDiscount: number | null;
    price: number | null;
    amount: number | null;
    commentForDB: string | null;
    isg: Date | null;

    skU_ID: string | null;
    specID: number | null;
    quantityAll: number | null;
    mnnID: string | null;
};

export type SaveOfferModel = {
    date: Date | null;
    region_ID: string | null;
    kp_ID: string | null;
    lot_ID: string | null;
    distrNumber: string | null;
    buyer: string | null;
    comment: string | null;

    listSKU: ItemSKU[];
    listSecondary: ItemSecondary[];
};

export type ItemSKU = {
    skU_Name: string | null;
    skU_ID: string | null;
    specID: number | null;
    planQuantity: number | null;
    planDiscount: number | null;
};

export type ItemSecondary = {
    inn: string | null;
    kpp: string | null;
    secondaryName: string | null;
};

export enum OfferPagePurpose {
    Show,
    CreateEmpty,
    CreateAuctionBased,
    CreateKPBased
};