import { Coordinates, PagedApiResponse } from './common';
import { EntitySettings } from './entitySettings';

export type Client = Coordinates & {
  customerId: number;
  organizationId: number;
  legalId: number;
  customerName: string;
  address: string;
  typeCust: string;
  phone: string;
  startTime: string;
  endTime: string;
  unloadTime: string;
  sourceCust: number;
};

export enum CurrentlyLoadingEntity {
  clientList,
  clientSettingsList,
}

export type ClientsState = {
  isLoading: CurrentlyLoadingEntity[];
  errorLoadingClientsList: Error | string | null;
  errorLoadingClientListSettings: Error | string | null;
  clients: PagedApiResponse<Client[]>;
  client: Client | null;
  selectedClientsId: number[];
  selectedClientSettings: EntitySettings;
  clientsWithBadCoordinatesCount: number | null;
};

export type ClientsWithBadCoordinatesCount = number;
