// @mui
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

export default function LoginIllustration({ ...other }: BoxProps) {
    return (
        <Box {...other}>
            <svg width="360" height="327" viewBox="0 0 360 327" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_308_9616)">
                    <path d="M19.2465 267.497C14.0798 246.831 13.5461 198.006 29.2463 160.999C64.2465 78.5 173.746 21.4999 264.246 92.4973C379.392 182.829 274.246 320.499 186.746 298.497C122.589 282.365 118.913 225.831 124.247 202.497C128.58 181.497 150.046 141.497 201.246 149.497C258.746 158.482 249.246 227.597 223.746 239.497C201.246 249.997 162.746 245.997 165.246 208.497" stroke="#C4CDD5"/>
                    <circle cx="29.2464" cy="159.999" r="7" fill="#C4CDD5"/>
                    <circle cx="266.246" cy="95.9994" r="7" fill="#C4CDD5"/>
                    <circle cx="184.246" cy="296.999" r="7" fill="#C4CDD5"/>
                    <path d="M216.908 23L195.945 32.694L199.483 45.0466L216.908 23Z" fill="#005EB8"/>
                    <path d="M216.908 23H184.246L194.186 29.074L216.908 23Z" fill="#005EB8"/>
                    <path d="M190.402 38.1545L195.945 32.694L216.908 23L194.186 29.074L190.402 38.1545Z" fill="#003DA5"/>
                    <path d="M111.256 280.004L128.356 292.061L123.189 302.492L111.256 280.004Z" fill="#005EB8"/>
                    <path d="M111.256 280.004L140.362 285.33L130.514 289.122L111.256 280.004Z" fill="#005EB8"/>
                    <path d="M132.405 297.831L128.356 292.061L111.256 280.004L130.514 289.122L132.405 297.831Z" fill="#003DA5"/>
                    <g filter="url(#filter0_di_308_9616)">
                        <rect x="73.2465" y="34" width="102" height="85" rx="8" fill="white"/>
                        <g filter="url(#filter1_di_308_9616)">
                            <rect x="86.2465" y="47" width="16" height="19" rx="4" fill="#005EB8"/>
                        </g>
                        <g filter="url(#filter2_i_308_9616)">
                            <rect x="111.246" y="50" width="39" height="5" rx="2.5" fill="#919EAB"/>
                        </g>
                        <g filter="url(#filter3_i_308_9616)">
                            <rect x="111.246" y="58" width="51" height="5" rx="2.5" fill="#919EAB"/>
                        </g>
                        <g filter="url(#filter4_i_308_9616)">
                            <rect x="86.2465" y="73" width="76" height="5" rx="2.5" fill="#C4CDD5"/>
                        </g>
                        <g filter="url(#filter5_i_308_9616)">
                            <rect x="86.2465" y="83" width="76" height="5" rx="2.5" fill="#C4CDD5"/>
                        </g>
                        <g filter="url(#filter6_i_308_9616)">
                            <rect x="86.2465" y="93" width="76" height="5" rx="2.5" fill="#C4CDD5"/>
                        </g>
                        <g filter="url(#filter7_i_308_9616)">
                            <rect x="86.2465" y="102" width="76" height="5" rx="2.5" fill="#C4CDD5"/>
                        </g>
                    </g>
                    <g filter="url(#filter8_di_308_9616)">
                        <rect x="269.246" y="180" width="74" height="62" rx="8" fill="white"/>
                        <g filter="url(#filter9_di_308_9616)">
                            <rect x="278.678" y="189.482" width="11.6078" height="13.8588" rx="4" fill="#005EB8"/>
                        </g>
                        <g filter="url(#filter10_i_308_9616)">
                            <rect x="296.815" y="191.671" width="28.2941" height="3.64706" rx="1.82353" fill="#919EAB"/>
                        </g>
                        <g filter="url(#filter11_i_308_9616)">
                            <rect x="296.815" y="197.506" width="37" height="3.64706" rx="1.82353" fill="#919EAB"/>
                        </g>
                        <g filter="url(#filter12_i_308_9616)">
                            <rect x="278.678" y="208.447" width="55.1373" height="3.64706" rx="1.82353" fill="#C4CDD5"/>
                        </g>
                        <g filter="url(#filter13_i_308_9616)">
                            <rect x="278.678" y="215.741" width="55.1373" height="3.64706" rx="1.82353" fill="#C4CDD5"/>
                        </g>
                        <g filter="url(#filter14_i_308_9616)">
                            <rect x="278.678" y="223.035" width="55.1373" height="3.64706" rx="1.82353" fill="#C4CDD5"/>
                        </g>
                        <g filter="url(#filter15_i_308_9616)">
                            <rect x="278.678" y="229.6" width="55.1373" height="3.64706" rx="1.82353" fill="#C4CDD5"/>
                        </g>
                    </g>
                    <g filter="url(#filter16_di_308_9616)">
                        <rect x="145.246" y="185" width="48" height="39" rx="8" fill="white"/>
                        <g filter="url(#filter17_i_308_9616)">
                            <rect x="155.246" y="195" width="22" height="5" rx="2.5" fill="#919EAB"/>
                        </g>
                        <g filter="url(#filter18_i_308_9616)">
                            <rect x="155.246" y="203" width="29" height="5" rx="2.5" fill="#919EAB"/>
                        </g>
                        <g filter="url(#filter19_i_308_9616)">
                            <rect x="155.246" y="210" width="22" height="5" rx="2.5" fill="#919EAB"/>
                        </g>
                    </g>
                </g>
                <defs>
                    <filter id="filter0_di_308_9616" x="25.2465" y="10" width="198" height="181" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="24"/>
                        <feGaussianBlur stdDeviation="24"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.568627 0 0 0 0 0.619608 0 0 0 0 0.670588 0 0 0 0.2 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_308_9616"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_308_9616" result="shape"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="-2"/>
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.87451 0 0 0 0 0.890196 0 0 0 0 0.909804 0 0 0 1 0"/>
                        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_308_9616"/>
                    </filter>
                    <filter id="filter1_di_308_9616" x="76.2465" y="41" width="36" height="39" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="5"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.368627 0 0 0 0 0.721569 0 0 0 0.24 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_308_9616"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_308_9616" result="shape"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="-2"/>
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.283107 0 0 0 0 0.554167 0 0 0 1 0"/>
                        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_308_9616"/>
                    </filter>
                    <filter id="filter2_i_308_9616" x="111.246" y="50" width="39" height="5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="-1"/>
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_308_9616"/>
                    </filter>
                    <filter id="filter3_i_308_9616" x="111.246" y="58" width="51" height="5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="-1"/>
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_308_9616"/>
                    </filter>
                    <filter id="filter4_i_308_9616" x="86.2465" y="73" width="76" height="5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="-1"/>
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_308_9616"/>
                    </filter>
                    <filter id="filter5_i_308_9616" x="86.2465" y="83" width="76" height="5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="-1"/>
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_308_9616"/>
                    </filter>
                    <filter id="filter6_i_308_9616" x="86.2465" y="93" width="76" height="5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="-1"/>
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_308_9616"/>
                    </filter>
                    <filter id="filter7_i_308_9616" x="86.2465" y="102" width="76" height="5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="-1"/>
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_308_9616"/>
                    </filter>
                    <filter id="filter8_di_308_9616" x="221.246" y="156" width="170" height="158" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="24"/>
                        <feGaussianBlur stdDeviation="24"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.568627 0 0 0 0 0.619608 0 0 0 0 0.670588 0 0 0 0.2 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_308_9616"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_308_9616" result="shape"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="-2"/>
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.87451 0 0 0 0 0.890196 0 0 0 0 0.909804 0 0 0 1 0"/>
                        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_308_9616"/>
                    </filter>
                    <filter id="filter9_di_308_9616" x="268.678" y="183.482" width="31.6078" height="33.8588" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="5"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.368627 0 0 0 0 0.721569 0 0 0 0.24 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_308_9616"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_308_9616" result="shape"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="-2"/>
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0.283107 0 0 0 0 0.554167 0 0 0 1 0"/>
                        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_308_9616"/>
                    </filter>
                    <filter id="filter10_i_308_9616" x="296.815" y="191.671" width="28.2941" height="3.64706" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="-1"/>
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_308_9616"/>
                    </filter>
                    <filter id="filter11_i_308_9616" x="296.815" y="197.506" width="37" height="3.64706" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="-1"/>
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_308_9616"/>
                    </filter>
                    <filter id="filter12_i_308_9616" x="278.678" y="208.447" width="55.1373" height="3.64706" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="-1"/>
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_308_9616"/>
                    </filter>
                    <filter id="filter13_i_308_9616" x="278.678" y="215.741" width="55.1373" height="3.64706" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="-1"/>
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_308_9616"/>
                    </filter>
                    <filter id="filter14_i_308_9616" x="278.678" y="223.035" width="55.1373" height="3.64706" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="-1"/>
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_308_9616"/>
                    </filter>
                    <filter id="filter15_i_308_9616" x="278.678" y="229.6" width="55.1373" height="3.64706" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="-1"/>
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"/>
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_308_9616"/>
                    </filter>
                    <filter id="filter16_di_308_9616" x="107.246" y="171" width="124" height="115" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="24"/>
                        <feGaussianBlur stdDeviation="19"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.568627 0 0 0 0 0.619608 0 0 0 0 0.670588 0 0 0 0.2 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_308_9616"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_308_9616" result="shape"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="-2"/>
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0.87451 0 0 0 0 0.890196 0 0 0 0 0.909804 0 0 0 1 0"/>
                        <feBlend mode="normal" in2="shape" result="effect2_innerShadow_308_9616"/>
                    </filter>
                    <filter id="filter17_i_308_9616" x="155.246" y="195" width="22" height="5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="-1"/>
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_308_9616"/>
                    </filter>
                    <filter id="filter18_i_308_9616" x="155.246" y="203" width="29" height="5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="-1"/>
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_308_9616"/>
                    </filter>
                    <filter id="filter19_i_308_9616" x="155.246" y="210" width="22" height="5" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="-1"/>
                        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"/>
                        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_308_9616"/>
                    </filter>
                    <clipPath id="clip0_308_9616">
                        <rect width="360" height="327" fill="white"/>
                    </clipPath>
                </defs>
            </svg>

        </Box>
    );
}
