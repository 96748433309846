import {createContext, ReactNode, useEffect, useReducer, useState} from 'react';
import {dispatch as appDispatch} from "../redux/store";
// utils
import axios from '../utils/axiosDistributor';
import {clearSession, isValidToken, setSession} from '../utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/auth';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  VerifyLogin = 'VERIFY_LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.VerifyLogin]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isRegistered: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isRegistered: false,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'LOGIN':
      return {
        ...state,
        isRegistered: false,
        user: action.payload.user,
      };
    case 'VERIFY_LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        isRegistered: false,
        user: null,
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: false,
        isRegistered: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: ReactNode;
};

function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    try {
      let accessToken = window.localStorage.getItem('accessToken');
      let refToken = window.localStorage.getItem('refreshToken');

      if (accessToken && refToken)  {
        if (!isValidToken(accessToken)) {
          await refreshToken(accessToken, refToken);
        } else {
          setSession(accessToken, refToken, await initialize);
        }

        const user = await getUser();
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: true,
            user: user,
          },
        });
      } else {
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: Types.Initial,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  };

  const login = async (email: string) => {
    await axios.post('/auth/login', {
      email
    });
    
    const user = {"email": email};
    dispatch({
      type: Types.Login,
      payload: {
        user
      },
    });
  };

  const verifyLogin = async (email: string, code: string) => {
    const verifyLoginResponse = await axios.post('/auth/verify-login', {
      email,
      code,
    });
    const { accessToken, refreshToken } = verifyLoginResponse.data;

    setSession(accessToken, refreshToken, await initialize);
    
    const user = await getUser();
    dispatch({
      type: Types.VerifyLogin,
      payload: {
        user
      },
    });
  };

  const refreshToken = async (accessToken: string, refreshToken: string) => {
    const response = await axios.post('/auth/refresh-token', {
      accessToken,
      refreshToken,
    });
    
    if (response.data) {
      setSession(response.data.accessToken, response.data.refreshToken, await initialize);
    }
  };

  const register = async (email: string, firstName: string, lastName: string, inn: string) => {
    await axios.post('/auth/register', {
      email,
      firstName,
      lastName,
      inn
    });

    const user = {"email": email};
    dispatch({
      type: Types.Register,
      payload: {
        user
      },
    });
  };

  const verifyRegistration = async (email: string, code: string) => {
    await axios.post('/auth/verify-registration', {
      email,
      code,
    });
  };

  const getUser = async () => {
    const response = await axios.get('/user');
    return response.data;
  };

  const logout = async () => {
    clearSession();
    dispatch({ type: Types.Logout });
    appDispatch({ type: 'RESET_STORE'});
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        verifyLogin,
        refreshToken,
        verifyRegistration
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
