import { format, getTime, formatDistanceToNow } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date: Date | string | number) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateShot(date: Date | string | number) {
  return format(new Date(date), 'dd.MM.yyyy');
}

export function fDateFrom(date: Date | string | number) {
  return `${format(new Date(date), 'yyyy-MM-dd')}T00:00:00`;
}

export function fDateTo(date: Date | string | number) {
  return `${format(new Date(date), 'yyyy-MM-dd')}T23:59:59`;
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), 'dd MMM yyyy p');
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}

export function isValidDate(date: Date | null | undefined) {
  if (date === null || date === undefined)
    return false;

  if (isNaN(date.getTime()))
    return false;

  const from = Date.parse('01/01/1900');
  const to = Date.parse('01/01/2100');

  if (date.getTime() > from && date.getTime() < to)
    return true;
  else
    return false;
}


