import { dispatch } from '../store';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axiosDelivery from '../../utils/axiosDelivery';
// @types
import { Depot, DepotState } from 'src/@types/depot';
import { PagedApiResponse } from 'src/@types/common';
// mock
import { _depotsPagedApiResponse } from 'src/_mock/_depots';

// ----------------------------------------------------------------------

const initialState: DepotState = {
  isLoading: false,
  error: null,
  depots: {
    total: 0,
    currentPage: 0,
    totalPages: 0,
    data: [],
  },
  depot: null,
};

const slice = createSlice({
  name: 'depot',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET DEPOTS
    getDepotsSuccess(state, action) {
      state.isLoading = false;
      state.depots = action.payload;
    },

    // GET DEPOT
    getDepotSuccess(state, action) {
      state.isLoading = false;
      state.depot = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// Получение справочника складов
export function getDepotsList(pageSize: number, page: number, mock = false) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = mock
                ? _depotsPagedApiResponse
                : await axiosDelivery.get('/depots', {
                      params: {
                          size: pageSize,
                          page: page,
                      },
                  });
            dispatch(slice.actions.getDepotsSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// ----------------------------------------------------------------------

// Получение склада по ID
export function getDepot(depotId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: PagedApiResponse<Depot> = await axiosDelivery.get('/depots', {
        params: { depotId },
      });
      dispatch(slice.actions.getDepotSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

// Обновление координат склада
export function setDepotCoordinates(depotId: string, latitude: number, longitude: number) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const request = {
        id: depotId,
        pointDTO: {
          lon: longitude,
          lat: latitude,
        },
      };
      const response = await axiosDelivery.put('/depots/coordinates', {
        params: request,
      });
      console.log('response', response);
      dispatch(slice.actions.getDepotSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
