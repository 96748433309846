// ----------------------------------------------------------------------

import { AxiosResponse } from "axios";

export function randomNumber(number: number) {
  return Math.floor(Math.random() * number) + 1;
}

export function randomNumberRange(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function randomInArray(array: any) {
  return array[Math.floor(Math.random() * array.length)];
}

export function randomInteger(max: number) {
    return Math.floor(Math.random() * max);
}

export function mapToAxiosResponse(payload: any): AxiosResponse {
    return {
        data: payload,
        status: 200,
        statusText: '',
        headers: {},
        config: {},
        request: null
    };
}