import { PagedApiResponseExtended } from './common';
import { NomenclatureItem } from './nomenclature';

export type Request = {
    id: string;
    number: string;
    date: Date;
    lotNumber: string;
    tenderNumber: string;
    kpNumber: string;
};

export type RequestState = {
    isLoading: boolean;
    error: Error | string | null;
    loaded: boolean;
    requests: PagedApiResponseExtended<Request[]>;
    request: DetailRequest | null;
    filter: RequestFilter;
};

export type ShipmentRequest = {
    Regions: string[] | null;
    Personal: boolean | null;
    MNN: string | null;
    DateFrom: Date | null;
    DateTo: Date | null;
    ID_Lot: number | null;
    ID_KP: string | null;
    KPNumber: string | null;
    LotNumber: string | null;
    TenderNumber: string | null;
    Number: string | null;
};

export type RequestFilter = {
    MNN: NomenclatureItem | null;
    Personal: boolean | null;
    DateFrom: Date | null;
    DateTo: Date | null;
    ID_Lot: string | null;
    ID_KP: string | null;
    tenderNumber: string | null;
    lotNumber: string | null;
    number: string | null;
    numberKP: string | null;
    regions: string[] | null;
};

export type DetailRequest = {
    author: string | null;
    number: string | null;
    date: Date | null;
    region: string | null;
    tenderNumber: string | null;
    lotNumber: string | null;
    iD_Lot: string | null;
    kpNumber: string | null;
    iD_KP: string | null;
    listSKU: SKUItem[]
};

export type SaveRequestModel = {
    date: Date | null;
    region_ID: string | null;
    iD_KP: string | null;

    listSKU: SKUSaveItem[];
};

export type SKUSaveItem = {
    string_ID: string | null;
    skU_ID: string | null;
    skU_Name: string | null;
    quantity: number | null;
};

export type SKUItem = {
    _id: string | null;
    string_ID: string | null;
    skU_ID: string | null;
    sku: string | null;
    skU_Name: string | null;
    typePack: string | null;
    quantity: number | null;
    quantityAll: number | null;
    discount: number | null;
    price: number | null;
};

export enum RequestPagePurpose {
    Show,
    CreateKPBased
};