import { dispatch } from '../store';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axiosDistributor from '../../utils/axiosDistributor';
// @types
import { Request, RequestState, ShipmentRequest, RequestFilter, SaveRequestModel } from 'src/@types/request';
import { PagedApiResponse } from 'src/@types/common';
import { fDateFrom, fDateTo } from "../../utils/formatTime";

// ----------------------------------------------------------------------

const initialState: RequestState = {
  isLoading: false,
  error: null,
  loaded: false,
  requests: {
    total: 0,
    currentPage: 0,
    totalPages: 0,
    data: [],
    excess: false
  },
  request: null,
  filter: {
    MNN: null,
    ID_Lot: null,
    ID_KP: null,
    Personal: true,
    DateFrom: null,
    DateTo: null,
    tenderNumber: null,
    lotNumber: null,
    number: null,
    numberKP: null,
    regions: null
  }
};

const slice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getRequestsSuccess(state, action) {
      state.isLoading = false;
      state.loaded = true;
      state.error = null;
      state.requests = action.payload;
    },

    getRequestSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.request = action.payload;
    },
    setFilter(state, action) {
      state.filter = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getRequestsList(filter: RequestFilter) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let _request = {
        Regions: filter.regions,
        MNN: filter.MNN?.mnnId ?? null,
        Personal: filter.Personal ?? true,
        ID_Lot: Number(filter.ID_Lot) || null,
        ID_KP: filter.ID_KP ?? null,
        DateFrom: fDateFrom(filter.DateFrom ?? new Date()),
        DateTo: fDateTo(filter.DateTo ?? new Date()),
        KPNumber: filter.numberKP,
        LotNumber: filter.lotNumber,
        TenderNumber: filter.tenderNumber,
        Number: filter.number
      }
      const response = await axiosDistributor.post(
        '/requests/GetList',
        _request
      );
      (response.data.list as Request[]).sort((a, b) => new Date(a.date).getDate() - new Date(b.date).getDate() || a.number.localeCompare(b.number));
      let data = {
        total: 0,
        currentPage: 0,
        totalPages: 0,
        data: response.data.list,
        excess: response.data.excess
      }
      dispatch(slice.actions.getRequestsSuccess(data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getRequest(requestId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: PagedApiResponse<Request> = await axiosDistributor.get(`/requests/${requestId}`);
      dispatch(slice.actions.getRequestSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setRequestFilter(filter: RequestFilter = initialState.filter) {
  return async () => {
    try {
      dispatch(slice.actions.setFilter(filter));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRequestKPBased(offerId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosDistributor.get(`/requests/create/${offerId}`);
      dispatch(slice.actions.getRequestSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function saveRequest(saveRequestModel: SaveRequestModel) {
  await axiosDistributor.post(`/requests/save`, saveRequestModel);
}