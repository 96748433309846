import axios from 'axios';
// config
import { HOST_API } from '../config';
import {clearSession} from "./jwt";
import {dispatch as appDispatch} from "../redux/store";

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
    baseURL: HOST_API,
});

axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.status === 401) {
            clearSession();
            appDispatch({ type: 'RESET_STORE'});
            window.location.href = '/';
        }
        return Promise.reject(error.response && error.response.data || 'Something went wrong');
    }
);

export default axiosInstance;
