import _mock from './_mock';
import { Client } from 'src/@types/client';
import { formatRFC3339 } from 'date-fns';
import { PagedApiResponse } from '../@types/common';
import { AxiosResponse } from 'axios';
import { mapToAxiosResponse } from './funcs';

const total = 30;

function generateClient(index: number): Client {
  return {
    customerId: index,
    endTime: formatRFC3339(_mock.dateRandom()),
    legalId: _mock.idRandomInteger(1000),
    customerName: _mock.company(index),
    address: _mock.address.fullAddress(index),
    organizationId: index + 1000,
    phone: _mock.phoneNumber(index),
    sourceCust: index + 10000,
    startTime: formatRFC3339(_mock.time(index)),
    typeCust: Math.floor(index / 10).toString(),
    unloadTime: formatRFC3339(_mock.time(index)),
    pointLat: Math.random() * 100,
    pointLon: Math.random() * 100,
  };
}

const clients: Client[] = new Array(total).fill(1).map((value, index) => generateClient(index));

export const _clientsPagedApiResponse: AxiosResponse<PagedApiResponse<Client[]>> = mapToAxiosResponse({
  data: {
    total: total,
    currentPage: 0,
    totalPages: 2,
    data: clients,
  },
});
