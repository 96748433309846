import * as Yup from 'yup';
import { useState } from 'react';
import {Link as RouterLink, useNavigate} from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import {PATH_AUTH, PATH_DASHBOARD} from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import useLocales from "../../../hooks/useLocales";

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
  remember: boolean;
  afterSubmit?: string;
};

export default function LoginForm() {
  const { translate } = useLocales();
  
  const { login } = useAuth();
  const navigate = useNavigate();

  const isMountedRef = useIsMountedRef();
  
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
        .required(translate('authorization.errorBlock.emptyMail'))
        .matches(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            translate('authorization.errorBlock.invalidMail')
        ),
  });

  const defaultValues = {
    email: '',
    remember: true,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    try {
      await login(data.email);
      navigate(PATH_AUTH.verify);
    } catch (error) {
      console.error(error);
      reset();
      if (isMountedRef.current) {
        setError('afterSubmit', error);
      }
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

        <RHFTextField name="email" label={translate('authorization.mail')} />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        {/*<RHFCheckbox name="remember" label={translate('authorization.remember') as string} />
        <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
          {translate('authorization.forgot')}
        </Link>*/}
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
      >
        {translate('authorization.comeIn')}
      </LoadingButton>
    </FormProvider>
  );
}
