import { AxiosResponse } from "axios";
import { ClientsWithBadCoordinatesCount } from "../@types/client";
import { PagedApiResponse } from "../@types/common";
import { EntitySetting, EntitySettings } from "../@types/entitySettings";
import axiosDelivery from "../utils/axiosDelivery";
import { _clientsPagedApiResponse } from "../_mock";
import { _clientsWithBadCoordinatesDynamic } from "../_mock/clientsWithBadCoordinates";

export class ClientsController {
    public static async getClientsList(size: number, page: number, textSearch = '', noCoordinates: boolean, mock = false) {
        const response = mock ? _clientsPagedApiResponse :
            await axiosDelivery.get('/customers', {
                params: {
                    search: textSearch,
                    size: size,
                    page: page,
                    onlyWithoutLocation: noCoordinates
                },
            });
        console.log('response getClientsList: ', response);
        return response;
    }

    public static async getClient(clientId: string) {
        const response: AxiosResponse<PagedApiResponse<Client>> = await axiosDelivery.get('/customers', {
            params: { clientId },
        });
        return response;
    }

    public static async sendNewClientSetting(value: EntitySetting) {
        const response = await axiosDelivery.get('/???', {
            params: { value },
        });

        return response;
    }

    public static async sendDeleteClientSetting(values: EntitySettings) {
        const response = await axiosDelivery.get('/???', {
            params: { values },
        });

        return response;
    }

    public static async getClientsWithBadCoordinatesCount(mock = false) {
        const response: AxiosResponse<ClientsWithBadCoordinatesCount> = mock ? _clientsWithBadCoordinatesDynamic() :
            await axiosDelivery.get('/customers/withoutLocationCount', {
                params: {},
            });

        return response;
    }

    public static async putClientsCoordinates(clientId: number, latitude: number, longitude: number) {
        const response: AxiosResponse = await axiosDelivery.put('/customers/coordinates', {
            "id": clientId,
            "pointDTO": {
                "lon": longitude,
                "lat": latitude
            }
        });

        console.log('response getClientsWithBadCoordinatesCount: ', response);
        return response;
    }
}