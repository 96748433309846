import { createSlice } from '@reduxjs/toolkit';
import { PagedApiResponse } from 'src/@types/common';
import { Vehicle, VehicleDriver, VehicleState } from 'src/@types/vehicle';
// utils
import axiosDelivery from '../../utils/axiosDelivery';
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const pagedApiResponse = {
    total: 0,
    currentPage: 0,
    totalPages: 0,
    data: [],
};

const initialState: VehicleState = {
    isLoading: false,
    error: null,
    vehicle: null,
    vehicles: pagedApiResponse,
    vehicleDrivers: pagedApiResponse,
};

const slice = createSlice({
    name: 'vehicle',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET VEHICLE DRIVERS LIST
        getVehicleDriversSuccess(state, action) {
            state.isLoading = false;
            state.vehicleDrivers = action.payload;
        },

        // GET VEHICLES LIST
        getVehiclesSuccess(state, action) {
            state.isLoading = false;
            state.vehicles = action.payload;
        },

        // GET VEHICLE
        getVehicleSuccess(state, action) {
            state.isLoading = false;
            state.vehicle = action.payload;
        },
    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// Получить связки водителей и ТС
export function getVehicleDriversList(
    size: number,
    page: number,
    vehicleDriverId: number,
    vehicelId: number
) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response: PagedApiResponse<VehicleDriver[]> = await axiosDelivery.get(
                '/vehicleDrivers',
                {
                    params: {
                        vehicleDriverId: vehicleDriverId,
                        vehicleId: vehicelId,
                        size: size,
                        page: page,
                    },
                }
            );
            dispatch(slice.actions.getVehicleDriversSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// Получить весь странспорт
export function getVehiclesList(pageSize: number, page: number) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response: PagedApiResponse<Vehicle[]> = await axiosDelivery.get('/vehicles', {
                params: {
                    size: pageSize,
                    page: page,
                },
            });
            dispatch(slice.actions.getVehiclesSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}

// Получить транспорт по Id
export function getVehicle(vehicleId: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response: PagedApiResponse<Vehicle> = await axiosDelivery.get('/vehicles', {
                params: { id: vehicleId },
            });
            dispatch(slice.actions.getVehicleSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
