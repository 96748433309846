import { dispatch } from '../store';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axiosDistributor from '../../utils/axiosDistributor';
// @types
import { Offer, SaveOfferModel, OfferState, OfferRequest, OfferFilter } from 'src/@types/offer';
import { fDateFrom, fDateTo } from "../../utils/formatTime";

// ----------------------------------------------------------------------

const initialState: OfferState = {
  isLoading: false,
  error: null,
  loaded: false,
  offers: {
    total: 0,
    currentPage: 0,
    totalPages: 0,
    data: [],
    excess: false
  },
  offer: null,
  filter: {
    MNN: null,
    Personal: true,
    ID_Lot: null,
    DateFrom: null,
    DateTo: null,
    tenderNumber: null,
    lotNumber: null,
    ID_Status: null,
    number: null,
    regions: null,
    distrNumber: null,
    tenderConductingDate: null,
    tenderEndDate: null
  }
};

const slice = createSlice({
  name: 'offer',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getOffersSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.loaded = true;
      state.offers = action.payload;
    },

    getOfferSuccess(state, action) {
      state.isLoading = false;
      state.error = null;
      state.offer = action.payload;
    },

    setFilter(state, action) {
      state.filter = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getOffersList(filter: OfferFilter) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      let _request = {
        Regions: filter.regions,
        MNN: filter.MNN?.mnnId ?? null,
        Personal: filter.Personal ?? true,
        DateFrom: fDateFrom(filter.DateFrom ?? new Date()),
        DateTo: fDateTo(filter.DateTo ?? new Date()),
        ID_Lot: Number(filter.ID_Lot) || null,
        TenderNumber: filter.tenderNumber,
        LotNumber: filter.lotNumber,
        ID_Status: filter.ID_Status,
        Number: filter.number,
        DistrNumber: filter.distrNumber,
        TenderConductingDate: filter.tenderConductingDate ? fDateFrom(filter.tenderConductingDate) : null,
        TenderEndDate: filter.tenderEndDate ? fDateFrom(filter.tenderEndDate) : null
      }

      const response = await axiosDistributor.post(
        '/offers/GetList',
        _request);
      (response.data.list as Offer[]).sort((a, b) => new Date(a.date).getDate() - new Date(b.date).getDate() || a.number.localeCompare(b.number));
      let data = {
        total: 0,
        currentPage: 0,
        totalPages: 0,
        data: response.data.list,
        excess: response.data.excess
      }
      dispatch(slice.actions.getOffersSuccess(data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  }
}

// ----------------------------------------------------------------------

export function getOffer(offerId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosDistributor.get(`/offers/${offerId}`);
      dispatch(slice.actions.getOfferSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOfferAuctionBased(auctionId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosDistributor.get(`/offers/lot/create/${auctionId}`);
      dispatch(slice.actions.getOfferSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOfferKPBased(offerId: string) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosDistributor.get(`/offers/correction/create/${offerId}`);
      dispatch(slice.actions.getOfferSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOfferEmpty() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosDistributor.get(`/offers/create`);
      dispatch(slice.actions.getOfferSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function saveOffer(saveOfferModel: SaveOfferModel) {
  const response = await axiosDistributor.post(`/offers/save`, saveOfferModel);
  return response.data.kP_ID;
}

export async function printOffer(offerId: string) {
  const response = await axiosDistributor.get(`/offers/print/${offerId}`);
  return response.data;
}

export async function report(offerId: string) {
  const response = await axiosDistributor.get(`/offers/report/${offerId}`);
  return response.data;
}

export async function importExcel(file: File) {
  let formData = new FormData();
  formData.append("file", file);
  await axiosDistributor.post(`/offers/import`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
}

export async function getImportTemplate() {
  const response = await axiosDistributor.get(`/offers/import/template`, {
    responseType: "blob"
  });
  return response.data;
}

export async function getImportInstruction() {
  const response = await axiosDistributor.get(`/offers/import/instruction`, {
    responseType: "blob"
  });
  return response.data;
}

// Установка активного тендера
export function setOfferItem(item: Offer | null) {
  return async () => {
    try {
      dispatch(slice.actions.getOfferSuccess(item));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setOfferFilter(filter: OfferFilter = initialState.filter) {
  return async () => {
    try {
      dispatch(slice.actions.setFilter(filter));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}