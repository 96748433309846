import jwtDecode from 'jwt-decode';
import { verify, sign } from 'jsonwebtoken';
//
import axios from './axiosDistributor';

// ----------------------------------------------------------------------

const isValidToken = (accessToken: string) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode<{ exp: number }>(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const handleTokenExpired = (exp: number, updateToken?: () => void) => {
  let expiredTimer;

  window.clearTimeout(expiredTimer);
  const currentTime = Date.now();
  const timeLeft = exp * 1000 - currentTime;
  console.log(timeLeft);
  expiredTimer = window.setTimeout(() => {
    console.log('expired');
    updateToken?.();
  }, timeLeft);
};

const setSession = (accessToken: string, refreshToken: string, updateToken?: () => void) => {
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
  axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

  // This function below will handle when token is expired
  const decoded = jwtDecode<{ exp: number }>(accessToken);
  handleTokenExpired(decoded.exp, updateToken);
};

const clearSession = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  delete axios.defaults.headers.common.Authorization;
};

export { isValidToken, setSession, verify, sign, clearSession };
