import { Coordinates, PagedApiResponse } from './common';

export enum DepotType {
    depot = 1,
    crossdock = 2,
}

export type Depot = Coordinates & {
    depotId: number;
    description: string; // Название склада
    address: string;
    timeWindow: Date; // Время посещения
    type: DepotType;
};

export type DepotState = {
    isLoading: boolean;
    error: Error | string | null;
    depots: PagedApiResponse<Depot[]>;
    depot: Depot | null;
};
