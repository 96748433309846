import { createSlice } from '@reduxjs/toolkit';
// @types
import { ClientsState, CurrentlyLoadingEntity } from 'src/@types/client';
import { EntitySetting, EntitySettings } from '../../@types/entitySettings';
import { ClientsController } from '../../controllers/ClientsController';
//
import { dispatch } from '../store';


// ----------------------------------------------------------------------

const initialState: ClientsState = {
    isLoading: [],
    errorLoadingClientsList: null,
    errorLoadingClientListSettings: null,
    clients: {
        total: 0,
        currentPage: 0,
        totalPages: 0,
        data: []
    },
    client: null,
    selectedClientsId: [],
    selectedClientSettings: [],
    clientsWithBadCoordinatesCount: null
};

const includeLoadingState = (currentLoadingSet: CurrentlyLoadingEntity[], loadingEntityToAdd: CurrentlyLoadingEntity) => {
    var result = new Set(currentLoadingSet);
    result.add(loadingEntityToAdd);
    return Array.from(result);
}

const excludeLoadingState = (currentLoadingSet: CurrentlyLoadingEntity[], loadingEntityToDelete: CurrentlyLoadingEntity) => {
    var result = new Set(currentLoadingSet);
    result.delete(loadingEntityToDelete);
    return Array.from(result);
}

const slice = createSlice({
    name: 'clients',
    initialState,
    reducers: {
        // START LOADING CLIENT LIST
        startLoadingClientsList(state) {
            state.isLoading = includeLoadingState(state.isLoading, CurrentlyLoadingEntity.clientList);
            state.errorLoadingClientsList = null;
        },

        // HAS ERROR LOADING CLIENT LIST
        hasErrorLoadingClients(state, action) {
            state.isLoading = excludeLoadingState(state.isLoading, CurrentlyLoadingEntity.clientList);
            state.errorLoadingClientsList = action.payload;
        },

        // GET CLIENTS
        getClientsListSuccess(state, action) {
            state.isLoading = excludeLoadingState(state.isLoading, CurrentlyLoadingEntity.clientList);
            state.clients = action.payload;
            state.errorLoadingClientsList = null;
        },

        // GET CLIENT
        getClientSuccess(state, action) {
            //state.isLoading = false;
            state.client = action.payload;
            state.errorLoadingClientsList = null;
        },

        // START LOADING CLIENT SETTINGS
        startLoadingClientSettingsList(state) {
            state.isLoading = includeLoadingState(state.isLoading, CurrentlyLoadingEntity.clientList);
            state.errorLoadingClientsList = null;
        },

        // GET CLIENT SETTINGS
        getClientSettingsSuccess(state, action) {
            state.isLoading = excludeLoadingState(state.isLoading, CurrentlyLoadingEntity.clientSettingsList);
            state.selectedClientSettings = action.payload;
            state.errorLoadingClientListSettings = null;
        },

        // HAS ERROR LOADING CLIENT SETTINGS
        hasErrorLoadingClientSettings(state, action) {
            state.isLoading = excludeLoadingState(state.isLoading, CurrentlyLoadingEntity.clientSettingsList);
            state.errorLoadingClientsList = action.payload;
        },

        // SET SELECTED CLIENT SETTINGS
        setCurrentlySelectedClients(state, action) {
            //state.isLoading = false;
            state.selectedClientsId = action.payload;
            state.errorLoadingClientsList = null;
        },

        // GET CLIENTS WITH BAD COORDINATES COUNT
        getClientsWithBadCoordinatesCountSuccess(state, action) {
            state.clientsWithBadCoordinatesCount = action.payload;
        },

        // DELETE CLIENT BY ID FROM STORE'S LIST
        deleteClientFromList(state, action) {
            state.clients.data = state.clients.data.filter(client => client.customerId !== action.payload);
            state.clients.total--;
        }
    },
});

// Reducer
export default slice.reducer;

// Actions
export const { actions } = slice;

// ----------------------------------------------------------------------

// Получить всех клиентов
export function getClientsList(size: number, page: number, textSearch = '', noCoordinates: boolean, mock = false) {
    return async () => {
        dispatch(slice.actions.startLoadingClientsList());
        try {
            var response = await ClientsController.getClientsList(size, page, textSearch, noCoordinates, mock);
            dispatch(slice.actions.getClientsListSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasErrorLoadingClients(error));
        }
    };
}

// ----------------------------------------------------------------------

// Получить клиента по ID
export function getClient(clientId: string) {
    return async () => {
        dispatch(slice.actions.startLoadingClientsList());
        try {
            var response = await ClientsController.getClient(clientId);
            dispatch(slice.actions.getClientSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasErrorLoadingClients(error));
        }
    };
}

// ----------------------------------------------------------------------

// Отправка новой настройки для клиента на сервер
export function sendNewClientSetting(value: EntitySetting) {
    return async () => {
        dispatch(slice.actions.startLoadingClientSettingsList());
        try {
            var response = await ClientsController.sendNewClientSetting(value);
            dispatch(slice.actions.getClientSettingsSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasErrorLoadingClientSettings(error));
        }
    };
}

// Отправка удаления настройки для клиента на сервер
export function sendDeleteClientSetting(values: EntitySettings) {
    return async () => {
        dispatch(slice.actions.startLoadingClientSettingsList());
        try {
            var response = await ClientsController.sendDeleteClientSetting(values);
            dispatch(slice.actions.getClientSettingsSuccess(response.data));
        } catch (error) {
            console.error(error);
            //invent some alert, no error needed instead of data
        }
    };
}

// Получить количество клиентов с неправильными координатами
export function getClientsWithBadCoordinatesCount(mock = false) {
    return async () => {
        try {
            var response = await ClientsController.getClientsWithBadCoordinatesCount(mock);
            console.log('response getClientsWithBadCoordinatesCount: ', response);
            dispatch(slice.actions.getClientsWithBadCoordinatesCountSuccess(response.data));
        } catch (error) {
            console.error(error);
        }
    };
}

// Обновить координаты у клиента
export function putClientsCoordinates(clientId: number | undefined, latitude: number, longitude: number) {
    return async () => {
        try {
            if (!clientId)
                throw Error('Обновление координат: Не задан идентификатор клиента');

            var response = await ClientsController.putClientsCoordinates(clientId, latitude, longitude);
            console.log('response putClientsCoordinates: ', response);
            await dispatch(slice.actions.deleteClientFromList(clientId));
            dispatch(getClientsWithBadCoordinatesCount());
        } catch (error) {
            console.error('putClientsCoordinates:', error);
            throw error;
        }
    };
}