import { createSlice } from '@reduxjs/toolkit';
import { GeocodeState } from 'src/@types/geocode';
// utils
import axiosDelivery from '../../utils/axiosDelivery';
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState: GeocodeState = {
    isLoading: false,
    error: null,
    geocodes: [],
};
type dataResponse = {
    streetName: string;
    description: string;
    point: {
        lon: number;
        lat: number;
    };
};
const mapData = (data: dataResponse[]) =>
    data.map((geocode) => ({
        streetName: geocode?.streetName,
        description: geocode?.description,
        pointLat: geocode?.point.lat,
        pointLon: geocode?.point.lon,
    }));
const slice = createSlice({
    name: 'geocode',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        // GET COORDINATES
        getCoordinatesSuccess(state, action) {
            const data = mapData(action.payload);
            state.isLoading = false;
            state.geocodes = data;
        },
    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// Получение координат по адресу
export function getCoordinates(address: string) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axiosDelivery.get('/geocode', {
                params: { address: address },
            });
            dispatch(slice.actions.getCoordinatesSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
