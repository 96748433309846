import { dispatch } from '../store';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axiosDistributor from '../../utils/axiosDistributor';
// @types
import { StatusState } from 'src/@types/status';

// ----------------------------------------------------------------------

const initialState: StatusState = {
    isLoading: false,
    error: null,
    loaded: false,
    statuses: []
};

const slice = createSlice({
    name: 'status',
    initialState,
    reducers: {
        // START LOADING
        startLoading(state) {
            state.isLoading = true;
        },

        // HAS ERROR
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },

        getStatusesSuccess(state, action) {
            state.isLoading = false;
            state.error = null;
            state.loaded = true;
            state.statuses = action.payload;
        }
    },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// Получение списка регионов
export function getStatuses() {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            const response = await axiosDistributor.get('/status');
            dispatch(slice.actions.getStatusesSuccess(response.data));
        } catch (error) {
            console.error(error);
            dispatch(slice.actions.hasError(error));
        }
    };
}
